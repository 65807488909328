import { singleton } from '../singleton'

class WebStorage {
  #storage
  #prefix = 'venatus'

  constructor(storage) {
    this.#storage = storage
  }

  getItem(key, defaultValue) {
    try {
      const value = this.#storage.getItem(`${this.#prefix}.${key}`)
      return value ? JSON.parse(value) : defaultValue
    } catch (err) {
      console.error("Can't get item from storage", err)

      return defaultValue
    }
  }

  setItem(key, value) {
    try {
      this.#storage.setItem(`${this.#prefix}.${key}`, JSON.stringify(value))
    } catch (err) {
      console.error("Can't set item to storage", err)
    }
  }

  removeItem(key) {
    try {
      this.#storage.removeItem(`${this.#prefix}.${key}`)
    } catch (err) {
      console.error("Can't remove item from storage", err)
    }
  }

  clear() {
    try {
      this.#storage.clear()
    } catch (err) {
      console.error("Can't clear storage", err)
    }
  }
}

export const WebLocalStorage = singleton(WebStorage, window.localStorage)
export const WebSessionStorage = singleton(WebStorage, window.sessionStorage)
