import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const Groupings = ({ value = [], options, onChange }) => {
  const handleChange = ({ target: { name, checked } }) => {
    const newValue = [...value]
    if (checked) {
      newValue.push(name)
    } else {
      const index = newValue.indexOf(name)
      newValue.splice(index, 1)
    }
    onChange({ name: 'groupBy', value: newValue })
  }

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col xs="auto">
          <span>Include: </span>
        </Col>
        {options.map((item) => (
          <Col xs="auto" key={item.name}>
            <Form.Check
              id={`report-grouping-${item.name}`}
              style={{ display: 'inline-block' }}
              type={'checkbox'}
              checked={value.includes(item.name)}
              onChange={handleChange}
              name={item.name}
              label={item.label || item.name}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Groupings
