import { ReactComponent as LoaderImage } from '../../images/preloader.svg'

const Loader = ({ styles }) => {
  return (
    <div data-testid="loader" className="d-flex justify-content-center align-items-center">
      <LoaderImage />
    </div>
  )
}

export default Loader
