import { useEffect, useState } from 'react'

import useAuth from '../hooks/useAuth'
import { getAccount } from '../requests/getAccount'
import { getInvoice } from '../requests/getInvoice'

const useInvoice = (organizationId, accountId, id) => {
  const [data, setData] = useState()
  const [userAccount, setAccount] = useState({ name: '', ext: {} })
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuth()

  useEffect(() => {
    if (!user || !id || !(accountId && organizationId)) {
      return
    }
    try {
      getInvoice(organizationId, accountId, id).then((response) => {
        if (response instanceof Error) {
          setError(response)
          setIsLoading(false)
          return
        }
        setData(response)
        setIsLoading(false)
      })

      getAccount(organizationId, accountId).then((account) => {
        setAccount(account)
      })
    } catch (error) {
      alert(JSON.stringify(error))
    }
  }, [accountId, organizationId, id, user])

  return {
    data,
    error,
    isLoading,
    userAccount,
  }
}

export default useInvoice
