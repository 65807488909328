import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import classNames from 'classnames'

import useAuth from '../../hooks/useAuth'
import { config } from '../../utils/config'
import Preloader from '../general/Preloader'

const API = config('REPORTER_API')

const ERROR_TIMEOUT = 20000

/**
 *
 * @param {'historyQuery'|'query'} type
 * @param {string} org
 * @param {string} account
 * @returns
 */
const getIframeUrl = (type, org, account) => {
  const ts = Math.round(Date.now() / 1000)

  return fetch(`${API}tipalti/organization/${org}/account/${account}/${type}?time=${ts}`, {
    credentials: 'include',
    mode: 'cors',
  })
}

const PaymentsDetails = () => {
  const { activeAccount, activeOrg, user, isLoading: isUserLoading } = useAuth()

  const [isLoadingPayments, setIsLoadingPayments] = useState(true)
  const [iframePaymentHeight, setIframePaymentHeight] = useState('0px')
  const [paymentsError, setPaymentsError] = useState(null)
  const [paymentsUrl, setPaymentsUrl] = useState(null)
  const [paymentsTimeout, setPaymentsTimeout] = useState(null)

  useEffect(() => {
    if (isUserLoading) {
      return
    }

    if (!user || !activeAccount) {
      return
    }

    const organization = activeOrg
    const accountId = activeAccount

    const paymentsTimeout = setTimeout(() => {
      setPaymentsError(new Error())
      setIsLoadingPayments(false)
    }, ERROR_TIMEOUT)
    setPaymentsTimeout(paymentsTimeout)

    getIframeUrl('query', organization, accountId)
      .then((res) => res.json())
      .then((url) => setPaymentsUrl(url))
      .catch((e) => {
        setPaymentsError(e)
        clearTimeout(paymentsTimeout)
        setPaymentsTimeout(null)
      })
  }, [activeAccount, activeOrg, isUserLoading, user])

  const tipaltiHandler = useCallback(
    (event) => {
      if (event.data && event.data.TipaltiIframeInfo && event.data.TipaltiIframeInfo.height) {
        if (event.data.caller === 'PaymentDetails') {
          setIframePaymentHeight(`${event.data.TipaltiIframeInfo.height}px`)
          setIsLoadingPayments(false)
          clearTimeout(paymentsTimeout)
          setPaymentsTimeout(null)
        }
      }
    },
    [paymentsTimeout],
  )

  useEffect(() => {
    window.addEventListener('message', tipaltiHandler)
    return () => {
      window.removeEventListener('message', tipaltiHandler)
    }
  }, [tipaltiHandler])

  return (
    <div className="main-content mt-4 mt-4">
      <Preloader
        styles={{
          display: isLoadingPayments ? 'block' : 'none',
        }}
      />
      <Container fluid>
        <>
          <div className={classNames('mt-md-5', isLoadingPayments && 'd-none')}>
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <h1 className="header-title text-truncate">Payments</h1>
                </Col>
              </Row>
            </div>
          </div>
          {!isLoadingPayments && paymentsError && (
            <Row>
              <h3>Failed to load payments</h3>
            </Row>
          )}
          <Row
            className={classNames(
              'justify-content-center',
              (isLoadingPayments || paymentsError) && 'd-none',
            )}
            style={{
              height: iframePaymentHeight,
            }}
          >
            <iframe id="tipalti" title="payment" src={paymentsUrl} />
          </Row>
        </>
      </Container>
    </div>
  )
}

export default PaymentsDetails
