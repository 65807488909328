import { config } from '../utils/config'

const API = config('REPORTER_API')

export const getInvoices = async (organizationId, accountId) => {
  const response = await fetch(
    `${API}payments/organization/${organizationId}/account/${accountId}`,
    {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return response.json()
}
