import startOfToday from 'date-fns/startOfToday'
import subDays from 'date-fns/subDays'

import { formatLocaleString } from '@venatus/phoenix-theme/helpers'

import { config } from '../utils/config'
import { formatForReportRequest } from '../utils/dateFormatter'
import { getRequestBody } from './getReportData'

const todayStart = startOfToday()
const weekAgo = subDays(todayStart, 7)
const zeroReport = [...Array(7).keys()].map((i) => [
  formatForReportRequest(subDays(todayStart, 6 - i)),
  0,
  0,
])

const REPORTER_API = config('REPORTER_API')

const formatNumber = (number) => formatLocaleString(Number(number), { maximumFractionDigits: 2 })
const formatCurrency = (number) =>
  formatLocaleString(Math.abs(number), {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  })

function calculateECPM(revenue, impressions) {
  return impressions > 0 ? (revenue / impressions) * 1000 : 0
}

function formatDashboardResponse(data) {
  const parsedRows = data.split('\n').filter((row) => row.length)
  const [, values] = parsedRows
  let yesterdayRevenue = 0
  let yesterdayImp = 0
  let weekAgoRevenue = 0
  let weekAgoImp = 0
  let monthAgoRevenue = 0
  let monthAgoImp = 0

  if (values) {
    ;[yesterdayRevenue, yesterdayImp, weekAgoRevenue, weekAgoImp, monthAgoRevenue, monthAgoImp] =
      values.split('\t').map(Number)
  }

  const yesterdayECPM = calculateECPM(yesterdayRevenue, yesterdayImp)
  const weekAgoECPM = calculateECPM(weekAgoRevenue, weekAgoImp)
  const monthAgoECPM = calculateECPM(monthAgoRevenue, monthAgoImp)

  return [
    formatNumber(yesterdayImp),
    `${formatCurrency(yesterdayRevenue)}`,
    `${formatCurrency(yesterdayECPM)}`,
    formatNumber(weekAgoImp),
    `${formatCurrency(weekAgoRevenue)}`,
    `${formatCurrency(weekAgoECPM)}`,
    formatNumber(monthAgoImp),
    `${formatCurrency(monthAgoRevenue)}`,
    `${formatCurrency(monthAgoECPM)}`,
  ]
}

export async function getDashboard(organizationId, accountId) {
  const response = await fetch(
    `${REPORTER_API}report/dashboardsummary/organization/${organizationId}/account/${accountId}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/tsv',
      },
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch dashboard data')
  }

  const textValues = await response.text()

  return formatDashboardResponse(textValues)
}

export const getDashboardTrend = async (organizationId, accountId) => {
  const body = getRequestBody({
    granularity: 'DAY',
    from: weekAgo,
    to: todayStart,
  })
  const response = await fetch(
    `${REPORTER_API}report/publisherstats/organization/${organizationId}/account/${accountId}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/tsv',
      },
      body: JSON.stringify(body),
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch dashboard data')
  }

  const result = (await response.text())
    .split('\n')
    .filter((x) => x.length)
    .map((item) => {
      let [date, revenue, impressions] = item.split('\t')
      return [date, formatNumber(impressions), `$${formatNumber(revenue)}`]
    })
  return result.length ? result : zeroReport
}
