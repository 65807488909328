import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { LoginForm } from '@venatus/phoenix-components'

import { ADMIN_ROLE } from '../../contexts/userContext'
import useAuth from '../../hooks/useAuth'
import logoImage from '../../images/logo.png'

const LoginPage = () => {
  const { handleAuthentication, handleTfaCancel, isTfaVisible } = useAuth()
  const history = useHistory()

  const onLogin = useCallback(
    async (values, helpers) => {
      try {
        const data = await handleAuthentication(values)
        if (data) {
          if (values.role === ADMIN_ROLE) {
            history.push('/organizations')
          } else {
            history.push('/accounts')
          }
        }
      } catch (error) {
        if (values.tfaCode) {
          helpers.setFieldError('tfaCode', 'Invalid code')
        } else {
          console.error(error)
          return toast.error('Unable to login')
        }
      }
    },
    [handleAuthentication, history],
  )

  return (
    <LoginForm
      imageUrl={logoImage}
      onLogin={onLogin}
      onReset={handleTfaCancel}
      isTfaModalVisible={isTfaVisible}
    />
  )
}

export default LoginPage
