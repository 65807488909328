import { makeStyles } from '@material-ui/core/styles'

import preloader from '../../images/preloader.svg'

const useStyles = makeStyles(() => ({
  preloader: {
    padding: '40px',
    textAlign: 'center',
    display: ({ display }) => display,
    '@media print': {
      display: 'none',
    },
  },
}))

const Preloader = ({ styles }) => {
  const classes = useStyles(styles)
  return (
    <div className={classes.preloader}>
      <img src={preloader} alt="preloader" />
    </div>
  )
}

export default Preloader
