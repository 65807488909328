import { useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'

import { PhoenixSelect } from '@venatus/phoenix-components'

import { countries, getCountryLabel } from '../../requests/countries'

export default function CountryColumnFilter({ column }) {
  const { filterValue, setFilter, preFilteredRows } = column
  const options = useMemo(() => {
    const options = new Set()

    preFilteredRows.forEach((row) => {
      options.add(column.accessor(row.original))
    })

    const arrayOptions = Array.from(options.values())
      .sort()
      .map((value) => {
        let label = value

        if (countries[value]) {
          label = getCountryLabel(value, countries[value])
        }

        return { value, label }
      })

    // add special options
    if (options.has('')) {
      arrayOptions.unshift({
        label: 'Empty',
        value: '',
      })
    }

    return arrayOptions
  }, [preFilteredRows, column])

  const selectValue = useMemo(
    () =>
      options.filter((o) => {
        return filterValue && filterValue.includes(o.value)
      }),
    [filterValue, options],
  )

  const changeHandler = useCallback(
    (event) => {
      const value = event.value
      if (value === 'All') {
        return setFilter(undefined)
      } else if (value === 'Empty') {
        return setFilter('')
      }
      const allValues = event.filter((elem) => elem.value !== undefined).map((elem) => elem.value)

      setFilter(allValues)
    },
    [setFilter],
  )

  return (
    <div className="form-group">
      <Form.Label>Country</Form.Label>
      <PhoenixSelect
        isMulti={true}
        placeholder="Select"
        options={options}
        value={selectValue}
        onChange={changeHandler}
        closeMenuOnSelect={false}
      />
    </div>
  )
}
