import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import { TFACodeForm } from '@venatus/phoenix-components'

import { config } from '../../utils/config'

const API = config('ACCOUNT_API')

const disableTfa = ({ tfaCode }) =>
  fetch(`${API}user/disableTfa`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tfaCode }),
  }).then((r) => r.json())

export const TFADisableForm = ({ isVisible, onHide }) => {
  const handleSubmit = useCallback(
    async (values, helpers) => {
      try {
        const data = await disableTfa({
          tfaCode: values.tfaCode,
        })
        onHide?.(data?.tfaEnabled)
        if (data?.tfaEnabled) {
          throw new Error('Failed to disable TFA')
        }
        toast('TFA Disabled', {
          type: 'success',
        })
      } catch (error) {
        helpers.setFieldError('tfaCode', 'Invalid code')
        throw error
      }
    },
    [onHide],
  )

  return (
    <TFACodeForm
      title="Disable Two-Factor Authentication"
      isVisible={isVisible}
      onHide={onHide}
      onSubmit={handleSubmit}
    />
  )
}
