import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getDashboard, getDashboardTrend } from '../requests/getDashboard'
import useAuth from './useAuth'

const useDashboard = () => {
  const history = useHistory()
  const { user, activeAccount, activeOrg } = useAuth()

  const [error, setError] = useState()
  const [data, setData] = useState([])
  const [trendData, setTrendData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!user) return

    if (!activeAccount) {
      setData([])
      setIsLoading(false)
      history.push('/accounts')
      return
    }

    Promise.all([
      getDashboard(activeOrg, activeAccount),
      getDashboardTrend(activeOrg, activeAccount),
    ])
      .then(([data, trend]) => {
        setData(data ?? [])
        setTrendData(trend)
      })
      .catch((e) => {
        setError(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeAccount, history, activeOrg, user])

  return {
    error,
    data,
    isLoading,
    trendData,
  }
}

export default useDashboard
