import columnNames from '../consts/columns'
import { config } from '../utils/config'
import { formatForReportRequest } from '../utils/dateFormatter'

const REPORTER_API = config('REPORTER_API')

export function getRequestBody(payload) {
  const includeDimensions = Array.isArray(payload.groupBy) && payload.groupBy.length
  const includeCountry = Array.isArray(payload.country) && payload.country.length
  const requestBody = {
    fromDate: formatForReportRequest(payload.from),
    toDate: formatForReportRequest(payload.to),
    granularity: {
      field: 'hour',
      value: payload.granularity,
    },
  }

  if (includeDimensions) {
    requestBody.dimensions = payload.groupBy
  }

  if (includeCountry) {
    requestBody.countries = payload.country
  }

  return requestBody
}

const formatResponse = (data, payload) => {
  return data
    .split('\n')
    .filter(Boolean)
    .map((item) => {
      const { groupBy, granularity } = payload
      let spreadArray = [columnNames.date, ...groupBy, columnNames.cpm, columnNames.impressions]
      if (granularity==='ALL'){
        spreadArray.shift();
      }
      const result = item.split('\t').reduce((acc, item, index) => {
        const key = spreadArray[index]
        if (index===0 && granularity!=='ALL'){
          acc[key] = formatForReportRequest(new Date(item), granularity)
        } else {
          acc[key] = item
        }
        return acc
      }, {})

      const eCpm = result.impressions > 0 ? (result.cpm / result.impressions) * 1000 : 0
      return { ...result, eCpm }
    })
}

const EXCEED_LIMIT = 'Query exceeded limit for bytes billed'

export const getReportData = async (payload, organizationId, accountId) => {
  const requestBody = getRequestBody(payload)
  const response = await fetch(
    `${REPORTER_API}report/publisherstats/organization/${organizationId}/account/${accountId}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/tsv',
      },
      body: JSON.stringify(requestBody),
    },
  )

  if (!response.ok) {
    const json = await response.json()

    if (json.message.includes(EXCEED_LIMIT)) {
      throw new Error(
        `You've tried to run a report that exceeds one or more limits in place. Please contact Venatus Publisher Operations for assistance.`,
      )
    }

    throw new Error('Failed to fetch reporting data')
  }

  const text = await response.text()
  return formatResponse(text, payload)
}
