import React, { memo, useCallback } from 'react'
import { Form } from 'react-bootstrap'

import { PhoenixSelect } from '@venatus/phoenix-components'

import { countriesSelectOptions } from '../../requests/countries'

const CountriesSelect = ({ name, onChange, defaultValue, ...rest }) => {
  const changeHandler = useCallback(
    (values) => {
      onChange({
        target: {
          name,
          value: values.map((innerValue) => innerValue.value),
        },
      })
    },
    [name, onChange],
  )

  return (
    <div className="form-group">
      <Form.Label>Countries</Form.Label>
      <PhoenixSelect
        placeholder="Select"
        options={countriesSelectOptions}
        onChange={changeHandler}
        defaultValue={defaultValue}
        isMulti
        closeMenuOnSelect={false}
      />
    </div>
  )
}

export default memo(CountriesSelect, (prev, next) => {
  return prev.value === next.value
})
