import React from 'react'

import { ErrorBoundaryWithRouter, NavBar } from '@venatus/phoenix-components'

import useAccounts from '../../hooks/useAccounts'
import useAuth from '../../hooks/useAuth'
import logoImage from '../../images/logo.png'
import classes from './Layout.module.css'
import './common.css'

const Layout = ({ children }) => {
  const { isAdmin, activeAccount, activeOrg, isLoading } = useAuth()
  const { data } = useAccounts(isLoading ? '' : activeOrg)
  const hasMultipleAccounts = data && data.length > 1

  const setActiveItem = (menuItems) => {
    const urlChunk = '/' + window.location.href.split('/').pop(-1)
    const treeTraversal = (tree, parent = null) => {
      tree.forEach((node) => {
        if (node.children && node.children.length > 0) {
          treeTraversal(node.children, node)
        }
        if (node.href === urlChunk && parent !== null) {
          parent.active = true
        }
      })
    }

    treeTraversal(menuItems)
    return menuItems
  }

  let menu = isLoading
    ? []
    : [
        (activeAccount || !isAdmin) && {
          id: 1,
          label: 'Dashboard',
          href: '/dashboard',
          icon: 'home',
        },
        isAdmin &&
          !activeOrg &&
          !activeAccount && {
            id: 2,
            label: 'Organizations',
            href: '/organizations',
            icon: 'users',
          },
        (activeOrg || !isAdmin) &&
          hasMultipleAccounts && {
            id: 3,
            label: 'Accounts',
            href: '/accounts',
            icon: 'server',
          },
        (activeAccount || !isAdmin) && {
          id: 4,
          label: 'Reporting',
          href: '/reporting',
          icon: 'pie-chart',
        },
        (activeAccount || !isAdmin) && {
          id: 5,
          label: 'Invoices',
          href: '/invoices',
          icon: 'dollar-sign',
        },
        (activeAccount || !isAdmin) && {
          id: 6,
          label: 'Account details',
          href: '/account-details',
          icon: 'file-text',
        },
        {
          id: 7,
          label: 'My user',
          href: '/user',
          icon: 'user',
        },
        (activeAccount || !isAdmin) && {
          id: 8,
          label: 'Payments',
          icon: 'credit-card',
          children: [
            {
              id: 9,
              label: 'Payment Details',
              href: '/paymentsdetails',
            },
            {
              id: 10,
              label: 'Payment History',
              href: '/paymentshistory',
            },
          ],
        },
        activeAccount &&
          !isAdmin && {
            id: 11,
            label: 'Support',
            icon: 'message-circle',
            externalHref: 'https://support.venatus.com/',
            target: '_blank',
          },
        isAdmin && activeAccount
          ? {
              id: 12,
              label: 'switch to admin',
              href: '/switch-to-admin',
              icon: 'sliders',
            }
          : {
              id: 13,
              label: 'logout',
              href: '/logout',
              icon: 'log-out',
            },
      ].filter(Boolean)

  menu = setActiveItem(menu)

  return (
    <>
      <NavBar
        menu={menu}
        logoHref="/dashboard"
        logoClassName={classes['logo']}
        logoSrc={logoImage}
        logoContainerClassName={classes['logo-container']}
      />
      <ErrorBoundaryWithRouter
        title="Sorry, we’re having trouble completing this action."
        subTitle="Please try again later or contact Venatus Publisher Operations for assistance."
      >
        {children}
      </ErrorBoundaryWithRouter>
    </>
  )
}

export default Layout
