import { BatchInterceptor } from '@mswjs/interceptors'
import browserInterceptors from '@mswjs/interceptors/lib/presets/browser'

import { logout } from '../requests/auth'

export const unauthorizedInterceptor = new BatchInterceptor({
  name: 'unauthorized-interceptor',
  interceptors: browserInterceptors,
})

unauthorizedInterceptor.on('response', (_, response) => {
  if (response.status === 401) {
    logout().finally(() => {
      window.location.assign('/login')
    })
  }
})

export default unauthorizedInterceptor
