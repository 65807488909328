import { useEffect, useRef } from 'react'

import { loadSpace } from '@usersnap/browser'

import { useUserContext } from '../contexts/userContext'
import { config } from '../utils/config'

const API_KEY = config('USK')

export default function useUsersnap() {
  const { user } = useUserContext()
  const loadedRef = useRef(false)

  useEffect(() => {
    if (!API_KEY || !user || loadedRef.current) {
      return
    }

    loadSpace(API_KEY).then((api) => {
      loadedRef.current = true
      api.init()
      api.on('open', (event) => {
        event.api.setValue('visitor', user.email)
        if (Array.isArray(user.accounts) && user.accounts.length > 0) {
          event.api.setValue('custom', { accounts: user.accounts.join(', ') })
        }
      })
    })
  }, [user])
}
