import { stringify as csvStringify } from 'csv-stringify/browser/esm/sync'

import columnNames, { csvColumns } from '../consts/columns'

export const mapParamsToMeta = ({ granularity, groupBy }) => {
  const groupings = groupBy.map((key) => ({ name: key }))
  let meta = [
    { name: columnNames.date },
    ...groupings,
    { name: columnNames.cpm },
    { name: columnNames.impressions },
    { name: columnNames.eCpm },
  ]
  if (granularity === 'ALL') {
    meta.shift()
  }
  return meta
}

export const mapParamsForRequest = (params) =>
  Object.keys(params).reduce((acc, key) => {
    let paramValue = Array.isArray(params[key]) ? [...params[key]] : params[key]

    return {
      ...acc,
      [key]: paramValue,
    }
  }, {})

export const isDateValid = (date) => {
  return date && !isNaN(date.getTime())
}

export const mapDataValues = (data) =>
  data.map((item) => {
    const updatedItem = { ...item }
    const hasSize = updatedItem.hasOwnProperty('width') && updatedItem.hasOwnProperty('height')

    if (hasSize) {
      updatedItem.size = `${updatedItem.width}x${updatedItem.height}`
      delete updatedItem.width
      delete updatedItem.height
    }
    return updatedItem
  })

export const downloadFile = (blob, filename) => {
  const anchor = document.createElement('a')
  const blobUrl = window.URL.createObjectURL(blob)

  anchor.setAttribute('href', blobUrl)
  anchor.setAttribute('download', filename)

  anchor.style.display = 'none'
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  window.URL.revokeObjectURL(blobUrl)
}

function transformHeadersForCSV(rows) {
  return rows.map((row) => {
    const newRow = {}
    Object.keys(row).forEach((key) => {
      const newKey = csvColumns[key] || key
      newRow[newKey] = row[key]
    })
    return newRow
  })
}

export const downloadCsv = (data, filename) => {
  const csv = csvStringify(transformHeadersForCSV(data), {
    header: true,
    quoted: true,
    quoted_empty: true,
  })

  downloadFile(new Blob([csv], { endings: 'native', type: 'text/csv' }), filename)
}
