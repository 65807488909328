import { memo, useMemo } from 'react'

import { formatLocaleString } from '@venatus/phoenix-theme/helpers'

import columnNames from '../../consts/columns'
import { countries } from '../../requests/countries'
import { formatForReportRequest } from '../../utils/dateFormatter'
import { datesSort, numberSort } from '../../utils/sorting'
import CountryColumnFilter from '../general/CountryColumnFilter'
import EmptyComponent from '../general/EmptyComponent'
import PagedTable from '../general/PagedTable/PagedTable'
import Preloader from '../general/Preloader'

const ReportingContent = ({ isLoading, loaded, meta, data, parameters }) => {
  const columnsConfig = useMemo(
    () => ({
      [columnNames.date]: {
        Header: 'Date (UTC)',
        accessor: 'toStartOfDay(DATETIME)',
        disableFilters: true,
        Cell: ({ value }) => {
          if (!value) {
            return null
          }
          return value
        },
        sortType: datesSort,
        filter: 'includesSome',
      },
      [columnNames.site]: {
        Header: 'Site',
        accessor: 'site_name',
        filter: 'includesSome',
      },
      [columnNames.winType]: {
        Header: 'Win Type',
        accessor: 'win_type',
        filter: 'includesSome',
        Cell: ({ value }) => value?.toUpperCase() || null,
      },
      [columnNames.cpm]: {
        Header: 'Revenue (USD)',
        accessor: 'cpm',
        disableFilters: true,
        sortType: numberSort,
        Cell: ({ value }) => {
          const number = Number(value)
          if (isNaN(number)) {
            return null
          }
          return formatLocaleString(Math.abs(number), {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })
        },
      },
      [columnNames.eCpm]: {
        Header: 'eCPM (USD)',
        accessor: 'eCpm',
        disableFilters: true,
        sortType: numberSort,
        Cell: ({ value }) => {
          const number = Number(value)
          if (isNaN(number)) {
            return null
          }
          return formatLocaleString(Math.abs(number), {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })
        },
      },
      [columnNames.isMobile]: {
        Header: 'Device',
        id: 'device',
        accessor: (row) => (!!++row.isMobile ? 'Mobile' : 'Desktop'),
        filter: 'includesSome',
      },
      [columnNames.country]: {
        Header: 'Country',
        accessor: 'country',
        Cell: ({ value }) => countries[value] ?? value,
        Filter: CountryColumnFilter,
      },
      [columnNames.size]: {
        Header: 'Size',
        accessor: 'size',
        disableFilters: true,
        sortType: (rowA, rowB, key) => {
          const a = rowA.original[key]
          const b = rowB.original[key]
          const [widthA, heightA] = a.split('x')
          const [widthB, heightB] = b.split('x')
          const delta = +widthA * +heightA - +widthB * +heightB
          return delta > 0 ? 1 : delta < 0 ? -1 : 0
        },
      },
      [columnNames.bidder]: {
        Header: 'Bidder',
        accessor: 'bidder',
        filter: 'includesSome',
      },
      [columnNames.placement]: {
        Header: 'Placement',
        accessor: 'placement_name',
        filter: 'includesSome',
      },
      [columnNames.impressions]: {
        Header: 'Impressions',
        accessor: 'impressions',
        disableFilters: true,
        sortType: numberSort,
        Cell: ({ value }) => {
          const number = Number(value)
          if (isNaN(number)) {
            return null
          }
          return formatLocaleString(number)
        },
      },
    }),
    [parameters],
  )

  const columns = useMemo(
    () =>
      meta.map((item) => {
        const format = columnsConfig[item.name] || {}
        return {
          Header: item.name,
          accessor: item.name,
          ...format,
        }
      }),
    [columnsConfig, meta],
  )

  const tableData = useMemo(() => data ?? [], [data])
  const defaultSortBy = useMemo(
    () => [
      {
        id: columnNames.date,
        desc: false,
      },
    ],
    [],
  )

  if (isLoading) {
    return <Preloader />
  }

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return <EmptyComponent message={loaded ? 'No Data' : 'Click "Run Report" to display data'} />
  }

  return (
    <PagedTable data={tableData} columns={columns} enableToolBar defaultSortBy={defaultSortBy} />
  )
}

export default memo(ReportingContent)
