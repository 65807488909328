export const formatForReportRequest = (date, type) => {
  if (!(date instanceof Date)) {
    return ''
  }
  const pad = (val, len) => {
    val = String(val)
    len = len || 2
    while (val.length < len) val = '0' + val
    return val
  }

  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hour = pad(date.getHours())
  const minute = pad(date.getMinutes())
  // return `${year}-${month}-${day} ${hour}:${minute}:00 T00:00`;
  switch (type) {
    case 'MINUTE':
      return `${year}-${month}-${day} ${hour}:${minute}`
    case 'HOUR':
      return `${year}-${month}-${day} ${hour}:00`
    case 'DAY':
      return `${year}-${month}-${day}`
    case 'MONTH':
      return `${year}-${month}`
    case 'YEAR':
      return `${year}`
    case 'ALL':
      return ''
    default:
      return `${year}-${month}-${day}`
  }
}
