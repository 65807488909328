export function singleton(cls, ...constructorParams) {
  let instance

  return {
    getInstance: () => {
      if (!instance) {
        instance = new cls(...constructorParams)
      }

      return instance
    },
  }
}
