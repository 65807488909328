import FeatherIcon from 'feather-icons-react'
import { memo, useRef } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Overlay,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap'

import { PhoenixSelect } from '@venatus/phoenix-components'

import classes from './Toolbar.module.scss'

const Toolbar = ({
  enable,
  pageSizeOptions,
  onPageSizeChange,
  pageSize,
  onDownload,
  onPrint,
  onToggleColsFilter,
  isColsFilterVisible,
  onToggleRowsFilter,
  isRowsFilterVisible,
  columns,
}) => {
  const colsFilterBtnRef = useRef(null)
  const rowsFilterBtnRef = useRef(null)

  if (!enable) return null

  return (
    <Card.Header>
      <Row className="align-items-center justify-content-end">
        {/* page size */}
        <Col xs="auto">
          <Form.Group className="mb-0 align-items-center" as={Row}>
            <Form.Label column className="mb-0 p-0 fs-5" xs="auto">
              Records per page
            </Form.Label>
            <Col xs="auto">
              <PhoenixSelect
                placeholder="Records per page"
                options={pageSizeOptions}
                onChange={onPageSizeChange}
                value={pageSize}
                styles={{
                  container: (provided) => ({ ...provided, padding: '0' }),
                  control: (provided) => ({ ...provided, minHeight: 'auto' }),
                  dropdownIndicator: (provided) => ({ ...provided, padding: '0 4px' }),
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        {/* download */}
        <Col xs="auto">
          <OverlayTrigger placement="top" overlay={<Tooltip>Download CSV</Tooltip>}>
            <Button variant="white default" size="sm" onClick={onDownload}>
              <FeatherIcon icon="download" size="1em" />
              &nbsp;Download
            </Button>
          </OverlayTrigger>
        </Col>
        {/* print */}
        <Col xs="auto">
          <OverlayTrigger placement="top" overlay={<Tooltip>Print table</Tooltip>}>
            <Button variant="white default" size="sm" onClick={onPrint}>
              <FeatherIcon icon="printer" size="1em" />
              &nbsp;Print
            </Button>
          </OverlayTrigger>
        </Col>
        {/* select columns */}
        <Col xs="auto">
          <OverlayTrigger placement="top" overlay={<Tooltip>Select columns</Tooltip>}>
            <Button
              variant="white default"
              size="sm"
              ref={colsFilterBtnRef}
              onClick={onToggleColsFilter}
            >
              <FeatherIcon icon="columns" size="1em" />
              &nbsp;Select columns
            </Button>
          </OverlayTrigger>
          <Overlay
            show={isColsFilterVisible}
            target={colsFilterBtnRef}
            placement="bottom"
            onHide={onToggleColsFilter}
            rootClose
          >
            <Popover className={classes['popover-z-index-fix']}>
              {columns.map((column) => {
                let header
                if (column.Header && typeof column.Header === 'function') {
                  header = column.Header()
                } else if (column.Header) {
                  header = column.Header
                } else {
                  header = column.id
                }
                return (
                  <div key={column.id} className={classes['dropdown-item']}>
                    <Form.Label className="mb-1">
                      <Form.Check
                        className={'d-inline-block'}
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      <div className={classes['check-label']}>{header}</div>
                    </Form.Label>
                  </div>
                )
              })}
            </Popover>
          </Overlay>
        </Col>
        {/* column filters */}
        <Col xs="auto">
          <OverlayTrigger placement="top" overlay={<Tooltip>Filter</Tooltip>}>
            <Button
              variant="white default"
              size="sm"
              ref={rowsFilterBtnRef}
              onClick={onToggleRowsFilter}
              disabled={!columns.some((column) => !column.disableFilters)}
            >
              <FeatherIcon icon="sliders" size="1em" />
              &nbsp;Filter
            </Button>
          </OverlayTrigger>

          <Overlay
            show={isRowsFilterVisible}
            target={rowsFilterBtnRef}
            placement="bottom"
            onHide={onToggleRowsFilter}
            rootClose
          >
            <Popover className={classes['popover-z-index-fix']}>
              {columns.map((col, i) => {
                return col.canFilter ? col.render('Filter', { key: `filter-${col.id}` }) : null
              })}
            </Popover>
          </Overlay>
        </Col>
      </Row>
    </Card.Header>
  )
}

export default memo(Toolbar)
