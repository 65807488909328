import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { TFACodeForm } from '@venatus/phoenix-components'

import { config } from '../../utils/config'

const API = config('ACCOUNT_API')

const generateTfaSecret = () =>
  fetch(`${API}user/generateTfaSecret`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
  }).then((r) => r.json())

const enableTfa = ({ tfaCode }) =>
  fetch(`${API}user/enableTfa`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify({ tfaCode }),
  }).then((r) => r.json())

const getTfaUrl = ({ secret, name, issuer }) =>
  `otpauth://totp/${name}?secret=${secret}&issuer=${issuer}&algorithm=SHA1&digits=6&period=30`

export const TFAEnableForm = ({ isVisible, onHide }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tfaSecretUrl, setTfaSecretUrl] = useState('')

  useEffect(() => {
    if (isVisible) {
      setIsLoading(true)
      generateTfaSecret()
        .then(({ tfaSecret, userName, companyName }) => {
          setTfaSecretUrl(
            getTfaUrl({
              secret: tfaSecret,
              name: userName,
              issuer: companyName,
            }),
          )
        })
        .catch(() => {
          toast('Failed to load TFA secret', {
            type: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setTfaSecretUrl('')
    }
  }, [isVisible])

  const handleSubmit = useCallback(
    async (values, helpers) => {
      try {
        const data = await enableTfa({
          tfaCode: values.tfaCode,
        })
        onHide?.(data?.tfaEnabled)
        if (!data?.tfaEnabled) {
          throw new Error('Failed to enable TFA')
        }
        toast('TFA Enabled', {
          type: 'success',
        })
      } catch (error) {
        helpers.setFieldError('tfaCode', 'Invalid code')
        throw error
      }
    },
    [onHide],
  )

  return (
    <TFACodeForm
      title="Enable Two-Factor Authentication"
      isVisible={isVisible}
      tfaSecretUrl={tfaSecretUrl}
      isLoading={isLoading}
      onHide={onHide}
      onSubmit={handleSubmit}
    />
  )
}
