import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { WebLocalStorage } from '../../utils/WebStorage'
import Loader from '../general/Loader'

export const LogoutPage = () => {
  const { logout, user } = useAuth()

  const [isLoggedOut, setIsLoggedOut] = useState(false)

  useEffect(() => {
    if (user && !isLoggedOut) {
      WebLocalStorage.getInstance().clear()
      logout().then(() => {
        setIsLoggedOut(true)
      })
    }
  }, [logout, user, isLoggedOut])

  if (!isLoggedOut) {
    return <Loader />
  }

  return <Redirect to={'/login'} />
}
