import FeatherIcon from 'feather-icons-react'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { Card, Pagination, Table } from 'react-bootstrap'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useReactToPrint } from 'react-to-print'

import classNames from 'classnames'

import usePagePagination from '../../../hooks/usePagePagination'
import { downloadCsv } from '../../../utils'
import DefaultColumnFilter from '../DefaultColumnFilter'
import Toolbar from './Toolbar'

const recordsPerPageOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
]

const PagedTable = ({ data, columns, enableToolBar, downloadFileName, defaultSortBy }) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      filter: 'includesSome',
      canFilter: false,
    }),
    [],
  )
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageOptions,
    prepareRow,
    previousPage,
    allColumns,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: defaultSortBy ?? [],
        pageSize: recordsPerPageOptions[0].value,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  // download
  const handleDownload = useCallback(() => {
    const columnsVisibility = allColumns.reduce(
      (acc, column) => ({
        ...acc,
        [column.id]: column.isVisible,
      }),
      {},
    )
    const filteredData = data.map((row) =>
      Object.keys(row).reduce((acc, key) => {
        if (columnsVisibility[key]) {
          acc[key] = row[key]
        }
        return acc
      }, {}),
    )

    // filename with extension required for correct download
    const filename = downloadFileName ?? 'table.csv'

    downloadCsv(filteredData, filename)
  }, [data, downloadFileName, allColumns])

  // print
  const tableToPrintRef = useRef(null)

  const reactToPrintContent = useCallback(() => {
    return tableToPrintRef.current
  }, [tableToPrintRef])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Report',
    removeAfterPrint: true,
  })

  // columns filter
  const [isColumnsFilterVisible, setIsColsFilterVisible] = useState(false)

  const toggleColsFilter = useCallback(() => {
    setIsColsFilterVisible(!isColumnsFilterVisible)
  }, [setIsColsFilterVisible, isColumnsFilterVisible])

  // rows filter Popover
  const [isRowsFilterVisible, setIsRowsFilterVisible] = useState(false)

  const toggleRowsFilter = useCallback(
    () => setIsRowsFilterVisible(!isRowsFilterVisible),
    [setIsRowsFilterVisible, isRowsFilterVisible],
  )
  const recordsPerPageValue = useMemo(
    () => recordsPerPageOptions.find((option) => option.value === pageSize),
    [pageSize],
  )
  const handleRecordsPerPageChange = useCallback(
    ({ value }) => {
      setPageSize(value)
    },
    [setPageSize],
  )

  const { pages } = usePagePagination({ pageIndex, pageOptions, gotoPage })

  return (
    <Card>
      <Toolbar
        enable={enableToolBar}
        pageSizeOptions={recordsPerPageOptions}
        onPageSizeChange={handleRecordsPerPageChange}
        pageSize={recordsPerPageValue}
        onDownload={handleDownload}
        onPrint={handlePrint}
        onToggleColsFilter={toggleColsFilter}
        isColsFilterVisible={isColumnsFilterVisible}
        onToggleRowsFilter={toggleRowsFilter}
        isRowsFilterVisible={isRowsFilterVisible}
        columns={allColumns}
      />
      <Table ref={tableToPrintRef} size="sm" className="card-table table-nowrap" hover responsive>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps({ role: null })}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: classNames(
                        column.className,
                        column.canSort && 'is-sortable',
                        column.isSorted && 'is-sorted',
                        column.isSortedDesc && 'is-sorted-desc',
                      ),
                      role: null,
                    }),
                  )}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="fs-base" {...getTableBodyProps({ role: null })}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps({ role: null })}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                        role: null,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Card.Footer className="d-flex justify-content-between">
        <Pagination className="card-pagination pagination-tabs">
          <Pagination.Item
            className="ps-0 pe-4 border-end"
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          >
            <FeatherIcon icon="arrow-left" size="1em" className="me-1" /> Prev
          </Pagination.Item>
        </Pagination>
        <Pagination className="card-pagination pagination-tabs">{pages}</Pagination>
        <Pagination className="card-pagination pagination-tabs">
          <Pagination.Item
            className="ps-4 pe-0 border-start"
            disabled={!canNextPage}
            onClick={() => nextPage()}
          >
            Next <FeatherIcon icon="arrow-right" size="1em" className="ms-1" />
          </Pagination.Item>
        </Pagination>
      </Card.Footer>
    </Card>
  )
}

export default memo(PagedTable)
