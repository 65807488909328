import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import logoImage from '../../images/logo.png'

const VenatusLogo = ({ className, isBlank, height, width }) => {
  return (
    <Nav.Link as={NavLink} to="/dashboard" target={isBlank && '_blank'}>
      <img className={className} src={logoImage} alt="logotype" height={height} width={width} />
    </Nav.Link>
  )
}

export default VenatusLogo
