export const loadConfig = () => {
  window.VM = window.VM || {}
  window.VM._loaded = true
  if (document?.getElementById('vm-data-tag')?.hasAttributes()) {
    for (const attr of document.getElementById('vm-data-tag').attributes) {
      if (attr.name.startsWith('vm_') || attr.name.startsWith('VM_')) {
        window.VM[attr.name.replace('vm_', '').replace('VM_', '').toUpperCase()] = attr.value
      }
    }
  }
}

export const config = (key) => {
  if (!window.VM || !window.VM._loaded) {
    loadConfig()
  }

  const react_key = process.env['REACT_APP_' + key]
  const app_key = window.VM[key]
  return react_key || app_key
}
