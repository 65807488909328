import React, { useCallback, useState } from 'react'
import { Badge, Button, Card } from 'react-bootstrap'

import { TFADisableForm } from './TFADisableForm'
import { TFAEnableForm } from './TFAEnableForm'
import { TFAReloginNotice } from './TFAReloginNotice'

export const TFASection = ({ user }) => {
  const [isEnabled, setIsEnabled] = useState(user?.tfaEnabled)
  const [isUpdated, setIsUpdated] = useState(false)

  // Enable form modal controls
  const [isEnableFormVisible, setIsEnableFormVisible] = useState(false)

  const handleEnableClick = useCallback(() => {
    setIsEnableFormVisible(true)
  }, [setIsEnableFormVisible])

  const handleEnableFormHide = useCallback(
    (twoFactorAuthEnabled) => {
      setIsEnableFormVisible(false)
      if (twoFactorAuthEnabled !== undefined) {
        setIsEnabled(twoFactorAuthEnabled)
        setIsUpdated(true)
      }
    },
    [setIsEnableFormVisible],
  )

  // Disable form modal controls
  const [isDisableFormVisible, setIsDisableFormVisible] = useState(false)

  const handleDisableClick = useCallback(() => {
    setIsDisableFormVisible(true)
  }, [setIsDisableFormVisible])

  const handleDisableFormHide = useCallback(
    (twoFactorAuthEnabled) => {
      setIsDisableFormVisible(false)
      if (twoFactorAuthEnabled !== undefined) {
        setIsEnabled(twoFactorAuthEnabled)
        setIsUpdated(true)
      }
    },
    [setIsDisableFormVisible],
  )

  return (
    <Card>
      <Card.Header>
        <h2 className="mb-0">
          Two factor authentication&nbsp;
          {isEnabled && <Badge bg="success">Enabled</Badge>}
          {!isEnabled && <Badge bg="secondary">Disabled</Badge>}
        </h2>
      </Card.Header>
      <Card.Body className="p-5">
        {!isEnabled && (
          <Button variant="primary" onClick={handleEnableClick}>
            Enable 2FA
          </Button>
        )}
        {isEnabled && (
          <Button variant="primary" onClick={handleDisableClick}>
            Disable 2FA
          </Button>
        )}
        <TFAEnableForm isVisible={isEnableFormVisible} onHide={handleEnableFormHide} />
        <TFADisableForm isVisible={isDisableFormVisible} onHide={handleDisableFormHide} />
        <TFAReloginNotice show={isUpdated} tfaEnabled={isEnabled} />
      </Card.Body>
    </Card>
  )
}
