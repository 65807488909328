import FeatherIcon from 'feather-icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { Card, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { useOrganizations } from '../../hooks/useOrganizations'
import EmptyComponent from '../general/EmptyComponent'
import Preloader from '../general/Preloader'

export const Organizations = () => {
  const { user, selectOrganization } = useAuth()
  const { data, isLoading } = useOrganizations(user?.organization)
  const [search, setSearch] = useState('')
  const history = useHistory()

  const handleSwitchTo = useCallback(
    (id) => {
      selectOrganization(id)
      history.push('/accounts')
    },
    [history, selectOrganization],
  )

  const organizations = useMemo(() => {
    if (!data) {
      return []
    }
    let accounts = data
    if (search) {
      const trimmedSearch = search.toUpperCase().trim()
      accounts = data.filter((account) => account.name?.toUpperCase().includes(trimmedSearch))
    }

    return accounts.map((org, index) => (
      <ListGroup.Item key={org.id} action onClick={() => handleSwitchTo(org.id)}>
        {org.name}
      </ListGroup.Item>
    ))
  }, [data, handleSwitchTo, search])

  if (isLoading) {
    return <Preloader />
  }

  if (!data?.length) {
    return <EmptyComponent />
  }

  return (
    <div className="main-content mt-4 mt-4">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">Admin</h6>
                <h1 className="header-title text-truncate">Organizations</h1>
              </Col>
            </Row>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col xs={12}>
            <Card>
              <ListGroup size="sm" className="card-table table-nowrap">
                <ListGroup.Item>
                  <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value ? e.target.value : undefined)}
                    />
                    <InputGroup.Text>
                      <FeatherIcon icon="search" size="1em" />
                    </InputGroup.Text>
                  </InputGroup>
                </ListGroup.Item>
                {organizations}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
