import { config } from '../utils/config'

const API = config('ACCOUNT_API')

export const getOrganizations = async () => {
  try {
    const response = await fetch(`${API}organizations/`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
}
