import FeatherIcon from 'feather-icons-react'
import React, { useCallback, useEffect, useRef } from 'react'
import { Alert, Button, Card, Col, Container, Row, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import fnsFormat from 'date-fns/format'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'

import { formatLocaleString } from '@venatus/phoenix-theme/helpers'

import useInvoice from '../../hooks/useInvoice'
import ErrorComponent from '../general/ErrorComponent'
import Preloader from '../general/Preloader'
import VenatusLogo from '../general/VenatusLogo'

const formatDollar = (value) => {
  return formatLocaleString(Math.abs(Number(value)), {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  })
}

const Invoice = () => {
  const { orgId, accId, id } = useParams()
  const { data, error, isLoading, userAccount } = useInvoice(orgId, accId, id)
  const originalTitle = useRef(document.title)

  useEffect(() => {
    if (data) {
      const formattedDate = fnsFormat(new Date(data.year, data.month, 0), 'MMMM yyyy')
      document.title = `${userAccount?.name} Inv-${data.id} - ${formattedDate}`
    }

    return () => {
      document.title = originalTitle.current
    }
  }, [data, userAccount])

  const invoiceToPrintRef = useRef(null)

  const reactToPrintContent = useCallback(() => {
    return invoiceToPrintRef.current
  }, [invoiceToPrintRef])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `Invoice ${id}`,
    removeAfterPrint: true,
  })

  if (isLoading) {
    return <Preloader />
  }

  if (error) {
    return <ErrorComponent />
  }

  const accountName = userAccount?.name
  const { country, zip, city, addressLine1, addressLine2, companyName } = userAccount?.ext
  const correctDate = lastDayOfMonth(new Date(data.year, data.month, 0))
  const raisedDate = fnsFormat(correctDate, 'do MMMM yyyy')
  const revenue = data.revenue
  const vatDue = data.country === 'GB' ? data.revenue * 0.2 : 0
  const invoiceTotal = revenue + vatDue
  const bankVat = data.bankVat

  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <div className="mt-md-5 print-hidden">
              <div className="header-body">
                <Row className="align-items-center">
                  <Col>
                    <h6 className="header-pretitle">Payments</h6>
                    <h1 className="header-title">Invoice #{data.id}</h1>
                  </Col>
                  <Col xs="auto">
                    <Button variant="primary" className="lift" onClick={handlePrint}>
                      <FeatherIcon icon="printer" size="1em" className="me-1" /> Print invoice
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <Card>
              <Card.Body className="p-5" ref={invoiceToPrintRef}>
                <Row>
                  <Col className="text-center">
                    <VenatusLogo height="45" isBlank />
                    <h2 className="mb-2">We have raised an invoice on your behalf</h2>
                    <p className="text-muted mb-6">Invoice {data.id}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <h6 className="text-uppercase text-muted">Invoice from</h6>
                    <p className="text-muted mb-4">
                      <strong className="text-body">{companyName}</strong> <br />
                      {addressLine1} <br />
                      {addressLine2} <br />
                      {city}, {country} <br />
                      {zip}
                      {bankVat && (
                        <>
                          <br />
                          VAT Registration: {bankVat}
                        </>
                      )}
                    </p>
                    <h6 className="text-uppercase text-muted">Invoice date</h6>
                    <p className="mb-4">
                      <time dateTime={correctDate.toISOString()}>{raisedDate}</time>
                    </p>
                  </Col>
                  <Col xs={12} md={6} className="text-md-end">
                    <h6 className="text-uppercase text-muted">Invoice to</h6>
                    <p className="text-muted mb-4">
                      <strong className="text-body">Venatus Media Limited</strong> <br />
                      Watchmaker Court <br />
                      31-35 St John's Lane <br />
                      London <br />
                      EC1M 4BJ <br />
                      VAT Registration: GB438577845
                    </p>
                    <h6 className="text-uppercase text-muted">Invoice ID</h6>
                    <p className="mb-4">#{data.id}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Table className="my-4" responsive>
                      <thead>
                        <tr>
                          <th className="px-0 bg-transparent border-top-0">
                            <span className="h6">Publisher</span>
                          </th>
                          <th className="px-0 bg-transparent border-top-0">
                            <span className="h6">Revenue (USD)</span>
                          </th>
                          <th className="px-0 bg-transparent border-top-0 text-end">
                            <span className="h6">VAT (USD)</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-0">{accountName}</td>
                          <td className="px-0">{formatDollar(revenue)}</td>
                          <td className="px-0 text-end">{formatDollar(vatDue)}</td>
                        </tr>
                        <tr>
                          <td className="px-0 border-top border-top-2">
                            <strong>Total amount due</strong>
                          </td>
                          <td colSpan="2" className="px-0 text-end border-top border-top-2">
                            <span className="h3">{formatDollar(invoiceTotal)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Alert variant="light">
                      The exchange rate used can be found at:
                      http://www.bankofengland.co.uk/boeapps/iadb/Rates.asp
                    </Alert>
                    <Alert variant="light">The VAT shown is your output tax due to HMRC</Alert>
                    <hr className="my-5" />
                    <h6 className="text-uppercase text-center">
                      Please keep this invoice for your records
                    </h6>
                    <p className="text-muted mb-0 text-center">
                      Venatus Finance Team -&nbsp;
                      <a href="mailto:finance@venatus.com">finance@venatus.com</a>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Invoice
