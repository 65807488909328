const { createMuiTheme } = require('@material-ui/core')

const theme = createMuiTheme({
  typography: {
    '@media print': {
      fontSize: 9,
    },
    button: {},
  },
  palette: {
    primary: {
      main: '#455769',
      light: '#5e7082',
      dark: '#2c3e50',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '@media print': {
          fontSize: '.4rem',
          padding: '.2rem',
        },
      },
    },
    MuiAppBar: {
      root: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        marginTop: '.5rem',
      },
    },
    MUIDataTableHeadCell: {
      data: {
        '@media print': {
          padding: '.2rem',
          fontSize: '.5rem',
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        '@media print': {
          padding: '.2rem',
          fontSize: '.5rem',
        },
      },
    },
  },
})

export default theme
