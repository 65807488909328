import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'

export const TFAReloginNotice = ({ show, tfaEnabled }) => {
  const { push } = useHistory()

  const gotoLogoutPage = () => {
    push('/logout')
  }

  return (
    <Modal size="sm" show={show} aria-labelledby="tfa-relogin-notice">
      <Modal.Header>
        <Modal.Title id="tfa-relogin-notice">2FA Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have successfully {tfaEnabled ? 'enabled' : 'disabled'} 2FA. Please log in to continue.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={gotoLogoutPage} variant="success">
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
