import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { FormButton, TextField } from '@venatus/phoenix-components'

import useAuth from '../../hooks/useAuth'
import { config } from '../../utils/config'
import { CountrySelectField } from '../general/FormikFields'
import Preloader from '../general/Preloader'

const API = config('ACCOUNT_API')

const REQUIRED_MSG = 'This field is required'

const schema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(REQUIRED_MSG),
  ext: yup.object({
    addressLine1: yup.string().required(REQUIRED_MSG),
    addressLine2: yup.string(),
    city: yup.string().required(REQUIRED_MSG),
    companyName: yup.string().required(REQUIRED_MSG),
    contactEmail: yup.string().required(REQUIRED_MSG),
    contactName: yup.string().required(REQUIRED_MSG),
    contactNumber: yup.string().required(REQUIRED_MSG),
    country: yup.string().required(REQUIRED_MSG),
    state: yup.string().required(REQUIRED_MSG),
    zip: yup.string().required(REQUIRED_MSG),
  }),
})

export default function AccountDetails() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const { user, activeAccount, activeOrg, isLoading: isUserLoading } = useAuth()

  const organization = activeOrg ?? user.organization
  const account = activeAccount ?? user?.accounts?.[0]

  useEffect(() => {
    if (isUserLoading) return

    if (activeAccount) {
      setIsLoading(true)
      fetch(`${API}organizations/${organization}/accounts/${account}`, {
        credentials: 'include',
        mode: 'cors',
      })
        .then((response) => response.json())
        .then((user) => {
          setData(user)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [user, activeAccount, isUserLoading, organization, account])

  const handleFormSubmit = useCallback(
    (data) => {
      return fetch(`${API}organizations/${organization}/accounts`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          id: data.id,
          name: data.name,
          ext: data.ext,
        }),
      })
        .then(() => {
          toast('Account details updated', {
            type: 'success',
          })
        })
        .catch((e) => {
          console.error(e)
          toast('Failed to submit form', {
            type: 'error',
          })
        })
    },
    [organization],
  )

  const initialValues = useMemo(
    () => ({
      id: data?.id,
      name: data?.name ?? '',
      ext: {
        addressLine1: data?.ext?.addressLine1 ?? '',
        addressLine2: data?.ext?.addressLine2 ?? '',
        city: data?.ext?.city ?? '',
        companyName: data?.ext?.companyName ?? '',
        contactEmail: data?.ext?.contactEmail ?? '',
        contactName: data?.ext?.contactName ?? '',
        contactNumber: data?.ext?.contactNumber ?? '',
        country: data?.ext?.country ?? '',
        state: data?.ext?.state ?? '',
        zip: data?.ext?.zip ?? '',
      },
    }),
    [data],
  )

  if (isLoading) {
    return <Preloader />
  }

  return (
    <div className="main-content">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body">
            <Row className="align-items-center">
              <Col>
                <h1 className="header-title text-truncate">Account details</h1>
              </Col>
            </Row>
          </div>
        </div>

        <Row className="justify-content-center mt-md-3">
          {!!data && (
            <Formik
              initialValues={initialValues}
              validateOnChange
              enableReinitialize
              validationSchema={schema}
              onSubmit={handleFormSubmit}
            >
              <Form>
                <Row>
                  <TextField name="name" label="Name" />
                  <TextField name="ext.companyName" label="Company name" />
                  <TextField name="ext.addressLine1" label="Address Line 1" />
                  <TextField name="ext.addressLine2" label="Address Line 2" />
                  <TextField name="ext.city" label="City" />
                  <TextField name="ext.state" label="State" />
                  <TextField name="ext.zip" label="Zip" />
                  <CountrySelectField name="ext.country" label="Country" />
                  <TextField name="ext.contactEmail" label="Contact Email" />
                  <TextField name="ext.contactName" label="Contact Name" />
                  <TextField name="ext.contactNumber" label="Contact Number" />
                </Row>
                <Row>
                  <Col xs={12} md={6} className="d-flex flex-row gap-2">
                    <FormButton label="Update account details" />
                  </Col>
                </Row>
              </Form>
            </Formik>
          )}
          {!data && <h2>Failed to load data</h2>}
        </Row>
      </Container>
    </div>
  )
}
