import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import useAuth from '../../hooks/useAuth'
import Preloader from '../general/Preloader'
import { PasswordSection } from './PasswordSection'
import { TFASection } from './TFASection'
import { UserInfoSection } from './UserInfoSection'

export default function MyUser() {
  const { user, isLoading: isUserLoading } = useAuth()

  if (isUserLoading) {
    return <Preloader />
  }

  return (
    <div className="main-content">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">Details</h6>
                <h1 className="header-title text-truncate">My User</h1>
              </Col>
            </Row>
          </div>
        </div>

        <Row className="justify-content-center">
          <Col xs={12}>
            <UserInfoSection user={user} />
            <TFASection user={user} />
            <PasswordSection user={user} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
