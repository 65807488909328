import FeatherIcon from 'feather-icons-react'
import React, { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'

import fnsFormat from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import ChartDefaults from '@venatus/phoenix-theme/components/vendor/ChartDefaults'

import useDashboard from '../../hooks/useDashboard'
import Preloader from '../general/Preloader'

const boxes = [
  { title: 'YESTERDAY IMPRESSIONS', icon: 'eye' },
  { title: 'YESTERDAY REVENUE', icon: 'dollar-sign' },
  { title: 'YESTERDAY ECPM', icon: 'target' },
  { title: 'LAST 7 DAYS IMPRESSIONS', icon: 'eye' },
  { title: 'LAST 7 DAYS REVENUE', icon: 'dollar-sign' },
  { title: 'LAST 7 DAYS ECPM', icon: 'target' },
  { title: 'LAST 30 DAYS IMPRESSIONS', icon: 'eye' },
  { title: 'LAST 30 DAYS REVENUE', icon: 'dollar-sign' },
  { title: 'LAST 30 DAYS ECPM', icon: 'target' },
]

const DashboardLayout = ({ children }) => {
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <div className="mt-md-5">
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <h6 className="header-pretitle">Overview</h6>
                  <h1 className="header-title text-truncate">Dashboard</h1>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
        <Row>{children}</Row>
      </Container>
    </div>
  )
}

const DashboardData = ({ data }) => {
  return data.slice(0, 9).map((value, index) => {
    const box = boxes[index]
    return (
      <Col xs={12} xl={4} key={`${index}-box`}>
        <Card>
          <Card.Body>
            <Row className="align-items-center">
              <Col>
                <h6 className="text-uppercase text-muted mb-2">{box.title}</h6>
                <span className="h2 mb-0">{value}</span>
              </Col>
              <Col xs="auto">
                <FeatherIcon icon={box.icon} size="20" className="text-muted" />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    )
  })
}

function Chart({ layout, size, className, ...props }) {
  useEffect(function () {
    ChartDefaults()

    return function () {
      const tooltip = document.getElementById('chart-tooltip')

      if (tooltip) {
        tooltip.parentNode.removeChild(tooltip)
      }
    }
  }, [])

  return <div className="chart" {...props} />
}

function ProgressChart({ data, ...props }) {
  if (!data) {
    return null
  }

  const labels = data.map((d) => fnsFormat(parseISO(d[0]), 'do LLL'))
  const maxNum =
    1.5 *
      Math.max(
        ...data.map((d) => (typeof d[2] === 'number' ? d[2] : +d[2].replace(/[,$]/g, ''))),
      ) || 1000

  const options = {
    tension: 0.35,
    borderWidth: 5,
    scales: {
      y: {
        display: true,
        type: 'linear',
        min: 0,
        max: maxNum / 1000,
        ticks: {
          beginAtZero: true,
          autoSkip: true,
          maxTicksLimit: 5,
          precision: 0,
          callback: function (value) {
            return '$' + value.toFixed(2) + 'k'
          },
        },
      },
      x: {
        gridLines: {
          display: false,
        },
      },
    },
  }

  const datasets = [
    {
      data: data.map((d) => {
        const numRaw = d[2]
        const num = typeof numRaw === 'number' ? numRaw : +numRaw.replace(/[,$]/g, '')
        return num / 1000 // format to thousands $XXsk
      }),
    },
  ]

  return (
    <Card {...props}>
      <Card.Body>
        <Chart>
          <Line data={{ labels: labels, datasets: datasets }} options={options} />
        </Chart>
      </Card.Body>
    </Card>
  )
}

export default function Dashboard() {
  const { isLoading, error, data, trendData } = useDashboard()

  if (isLoading) {
    return (
      <DashboardLayout>
        <Preloader />
      </DashboardLayout>
    )
  }

  if (error || !data) {
    return (
      <DashboardLayout>
        <h2>Failed to load data</h2>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <Row>
        <ProgressChart data={trendData} />
      </Row>
      <Row>
        <Container>
          <Row>
            <DashboardData data={data} />
          </Row>
        </Container>
      </Row>
    </DashboardLayout>
  )
}
