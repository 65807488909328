import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { getAccounts } from '../requests/getAccounts'

const useAccounts = (organizationId) => {
  return useQuery([organizationId, 'accounts'], {
    queryFn: async () => {
      const accounts = await getAccounts(organizationId)
      return accounts
    },
    enabled: Boolean(organizationId),
    onError: () => {
      toast('Failed to fetch accounts', { type: 'error' })
    },
  })
}

export default useAccounts
