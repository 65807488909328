const columns = {
  date: 'toStartOfDay(DATETIME)',
  site: 'site',
  winType: 'winType',
  cpm: 'cpm',
  eCpm: 'eCpm',
  isMobile: 'isMobile',
  country: 'country',
  size: 'size',
  bidder: 'bidder',
  placement: 'placement',
  impressions: 'impressions',
}

export default columns

export const csvColumns = {
  ...columns,
  cpm: 'revenue',
  'toStartOfDay(DATETIME)': 'date',
}
