import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    color: '#f44',
    padding: '40px',
    textAlign: 'center',
  },
}))

const ErrorComponent = ({ message }) => {
  const classes = useStyles()
  const errorMessage = message || 'Some error occured'
  return (
    <div className={classes.container}>
      <h2>{errorMessage}</h2>
    </div>
  )
}

export default ErrorComponent
