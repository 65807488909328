import { useEffect, useState } from 'react'

import { getOrganizations } from '../requests/getOrganizations'

export const useOrganizations = (organizationId) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!organizationId) {
      return
    }
    getOrganizations().then((response) => {
      setData(response)
      setIsLoading(false)
    })
  }, [organizationId])

  return {
    data,
    isLoading,
  }
}
