import { useCallback, useState } from 'react'

import { getReportData } from '../requests/getReportData'
import { isDateValid, mapDataValues, mapParamsForRequest, mapParamsToMeta } from '../utils'
import useAuth from './useAuth'

const useReporting = (initialParameters = {}) => {
  const [data, setData] = useState(null)
  const [queryMeta, setQueryMeta] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [parameters, setParameters] = useState(initialParameters)
  const { activeAccount, activeOrg } = useAuth()
  const [loaded, setLoaded] = useState(false)

  const handleSubmit = useCallback(async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      setQueryMeta(mapParamsToMeta(parameters))
      const data = await getReportData(mapParamsForRequest(parameters), activeOrg, activeAccount)
      setData(mapDataValues(data))
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
      setLoaded(true)
    }
  }, [isLoading, parameters, activeOrg, activeAccount])

  const handleChange = useCallback(({ name, value }) => {
    const getUpdatedGroupBy = (params, name, value) => {
      const isCountry = name === 'country'
      const countryIndex = params.groupBy.indexOf('country')
      const isCountryChecked = countryIndex > -1
      const hasCountriesItems = isCountry && !!value.length

      if (
        !isCountry ||
        (hasCountriesItems && isCountryChecked) ||
        (!hasCountriesItems && !isCountryChecked)
      )
        return {}

      const updatedGroupBy = [...params.groupBy]

      if (hasCountriesItems) {
        updatedGroupBy.push('country')
      } else {
        updatedGroupBy.splice(countryIndex, 1)
      }

      return {
        groupBy: updatedGroupBy,
      }
    }

    setParameters((prev) => {
      const newParameters = {
        ...prev,
        [name]: value,
        ...getUpdatedGroupBy(prev, name, value),
      }

      setIsInvalid(
        !(
          isDateValid(newParameters.from) &&
          isDateValid(newParameters.to) &&
          newParameters.from <= newParameters.to
        ),
      )

      return newParameters
    })
  }, [])

  return {
    meta: queryMeta,
    data,
    error,
    handleChange,
    handleSubmit,
    isInvalid,
    isLoading,
    parameters,
    loaded,
  }
}

export default useReporting
