import { config } from '../utils/config'

const API = config('ACCOUNT_API')

export const getAccounts = async (organizationId) => {
  const response = await fetch(`${API}organizations/${organizationId}/accounts`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()
  return json
}
