import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

export const SwitchToAdmin = () => {
  const { isAdmin, selectAccount, selectOrganization } = useAuth()

  useEffect(() => {
    if (isAdmin) {
      selectAccount()
      selectOrganization()
    }
  }, [isAdmin, selectAccount, selectOrganization])

  return <Redirect to={'/'} />
}
