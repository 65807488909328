import React, { useCallback, useContext, useEffect, useState } from 'react'

import useAccounts from '../hooks/useAccounts'
import { WebLocalStorage, storageKeys } from '../utils/WebStorage'

/**
 * @typedef UserContext
 * @property {boolean} isLoading
 * @property {(user) => void} setUser
 * @property {() => void} updateUserData
 * @property {any} user
 * @property {boolean} isAdmin
 * @property {string} activeOrg
 * @property {string} activeAccount
 * @property {(value: string) => void} selectOrganization
 * @property {(value: string) => void} selectAccount
 */
import { config } from '../utils/config'

const API = config('ACCOUNT_API')

export const ADMIN_ROLE = 'ADMIN'

const fetchUser = () =>
  fetch(`${API}user/current`, {
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => response.json())
    .catch(() => null)

const fetchUserByEmail = (user) =>
  fetch(`${API}user/${user.organization}/${user.email}`, {
    credentials: 'include',
    mode: 'cors',
  })
    .then((res) => res.json())
    .catch(() => fetchUser())

const initialOrg = WebLocalStorage.getInstance().getItem(storageKeys.activeOrg, null)
const initialAccount = WebLocalStorage.getInstance().getItem(storageKeys.activeAccount, null)

export const UserContext = React.createContext({})

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [activeOrg, setActiveOrg] = useState(initialOrg)
  const [activeAccount, setActiveAccount] = useState(initialAccount)
  const [isLoading, setIsLoading] = useState(true)
  const { data: accounts } = useAccounts(activeOrg)

  const selectOrganization = useCallback((value) => {
    if (value) {
      WebLocalStorage.getInstance().setItem(storageKeys.activeOrg, value)
    } else {
      WebLocalStorage.getInstance().removeItem(storageKeys.activeOrg)
    }
    setActiveOrg(value)
  }, [])

  const selectAccount = useCallback((value) => {
    if (value) {
      WebLocalStorage.getInstance().setItem(storageKeys.activeAccount, value)
    } else {
      WebLocalStorage.getInstance().removeItem(storageKeys.activeAccount)
    }
    setActiveAccount(value)
  }, [])

  useEffect(() => {
    if (
      Array.isArray(accounts) &&
      accounts.length > 0 &&
      !activeAccount &&
      user &&
      user.role !== ADMIN_ROLE
    ) {
      const account = accounts[0]
      setActiveAccount(account.id)
      WebLocalStorage.getInstance().setItem(storageKeys.activeAccount, account.id)
    }
  }, [accounts, activeAccount, user])

  const updateUser = (data) => {
    setUser(data)
    setIsLoading(false)

    if (data?.organization) {
      const { organization } = data

      setActiveOrg(organization)
      WebLocalStorage.getInstance().setItem(storageKeys.activeOrg, organization)
    }
  }

  const updateUserData = useCallback(() => {
    setIsLoading(true)
    fetchUserByEmail(user).then(updateUser)
  }, [setIsLoading, user])

  useEffect(() => {
    fetchUser().then((data) => {
      setUser(data)
      setIsLoading(false)

      if (data?.organization && data?.role !== ADMIN_ROLE) {
        const { organization } = data

        setActiveOrg(organization)
        WebLocalStorage.getInstance().setItem(storageKeys.activeOrg, organization)
      }
    })
  }, [])

  const ctx = {
    user,
    setUser,
    isLoading,
    isAdmin: user?.role === ADMIN_ROLE,
    updateUserData,
    activeOrg,
    activeAccount,
    selectOrganization,
    selectAccount,
  }

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>
}

/**
 * @returns {UserContext}
 */
export const useUserContext = () => {
  /**
   * @type {import('../contexts/userContext').UserContext}
   */
  const ctx = useContext(UserContext)

  return ctx
}
