import FeatherIcon from 'feather-icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'

import useAccounts from '../../hooks/useAccounts'
import useAuth from '../../hooks/useAuth'
import { getOrganization } from '../../requests/getOrganization'
import EmptyComponent from '../general/EmptyComponent'
import Preloader from '../general/Preloader'

const Accounts = () => {
  const { user, isAdmin, activeOrg, activeAccount, selectAccount, selectOrganization } = useAuth()
  const organization = isAdmin ? activeOrg : user?.organization
  const [organizationDetails, setOrganizationDetails] = useState(null)
  const { data, isLoading } = useAccounts(organization)
  const [search, setSearch] = useState('')
  const history = useHistory()

  const handleSwitchTo = useCallback(
    (id) => {
      selectAccount(id)
      history.push('/dashboard')
    },
    [history, selectAccount],
  )

  useEffect(() => {
    if (organization) {
      getOrganization(organization).then((data) => setOrganizationDetails(data))
    }
  }, [organization])

  useEffect(() => {
    if (data?.length === 1 && !activeAccount) {
      handleSwitchTo(data[0].id)
    }

    if (Array.isArray(data) && data.length === 0) {
      history.push('/organizations')
      selectOrganization()
    }
  }, [activeAccount, data, handleSwitchTo, history, selectOrganization])

  let accounts = data ?? []

  if (search) {
    const trimmedSearch = search.toUpperCase().trim()
    accounts = data.filter((account) => account.name?.toUpperCase().includes(trimmedSearch))
  }

  if (!organization && isAdmin) {
    return <Redirect to={'/organizations'} />
  }

  if (isLoading) {
    return <Preloader />
  }

  if (!data?.length) {
    return <EmptyComponent />
  }

  return (
    <div className="main-content mt-4 mt-4">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">
                  {isAdmin
                    ? 'Admin'
                    : organizationDetails
                    ? `Organization: ${organizationDetails.name}`
                    : 'Loading...'}
                </h6>
                <h1 className="header-title text-truncate">Accounts</h1>
              </Col>
            </Row>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col xs={12}>
            <Card>
              <ListGroup size="sm" className="card-table table-nowrap">
                <ListGroup.Item>
                  <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value ? e.target.value : undefined)}
                    />
                    <InputGroup.Text>
                      <FeatherIcon icon="search" size="1em" />
                    </InputGroup.Text>
                  </InputGroup>
                </ListGroup.Item>
                {accounts.map((account, index) => (
                  <ListGroup.Item
                    key={account.id}
                    action
                    onClick={() => handleSwitchTo(account.id)}
                  >
                    {account.name ?? account.id}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Accounts
