import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'

import fnsFormat from 'date-fns/format'
import enLocale from 'date-fns/locale/en-US'

import { PhoenixSelect } from '@venatus/phoenix-components'

import CountriesSelect from '../general/CountriesSelect'
import classes from './Reporting.module.scss'

const dateNow = new Date()
const minDate = new Date(dateNow.getFullYear() - 5, 0, 1)

const DateRangePickerDay = ({ parameters, handleRangeChange }) => {
  const [maxDate, setMaxDate] = useState(new Date())

  useEffect(() => {
    // update max date to current time every 10 minutes
    const interval = setInterval(() => {
      setMaxDate(new Date())
    }, 1000 * 60 * 10)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const formatDate = useCallback((date) => fnsFormat(date, 'd MMM yyyy', { locale: enLocale }), [])

  const flatpickrOptions = useMemo(
    () => ({
      minDate,
      maxDate,
      formatDate,
      mode: 'range',
    }),
    [maxDate, formatDate],
  )
  return (
    <div className="form-group">
      <Form.Label>Report date range</Form.Label>
      <Form.Control
        value={[parameters.from, parameters.to]}
        as={Flatpickr}
        options={flatpickrOptions}
        onChange={handleRangeChange}
      />
    </div>
  )
}

const granularityOptions = [
  {
    value: 'HOUR',
    label: 'Hour',
  },
  {
    value: 'DAY',
    label: 'Day',
  },
  {
    value: 'MONTH',
    label: 'Month',
  },
  {
    value: 'ALL',
    label: 'Cumulative',
  },
]

const Range = (props) => {
  const { onChange, parameters } = props

  const granularityValue = useMemo(() => {
    return granularityOptions.find((o) => o.value === parameters.granularity)
  }, [parameters.granularity])

  const handleRangeChange = useCallback(
    ([from, to]) => {
      onChange({
        target: { name: 'from', value: from },
      })
      onChange({
        target: { name: 'to', value: to },
      })
    },
    [onChange],
  )

  const handleChangeGranularity = useCallback(
    ({ value }) => {
      onChange({
        target: {
          name: 'granularity',
          value,
        },
      })
    },
    [onChange],
  )

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col xs={12} md={6}>
          <DateRangePickerDay
            classes={classes}
            handleRangeChange={handleRangeChange}
            onChange={onChange}
            parameters={parameters}
          />
        </Col>
        <Col xs={12} md={3}>
          <div className="form-group">
            <Form.Label>Range Grouping</Form.Label>
            <PhoenixSelect
              placeholder="Select..."
              options={granularityOptions}
              onChange={handleChangeGranularity}
              value={granularityValue}
            />
          </div>
        </Col>

        <Col>
          <CountriesSelect name="country" onChange={onChange} />
        </Col>
      </Row>
    </Container>
  )
}

export default memo(
  Range,
  ({ parameters: prevParameters }, { parameters }) => parameters === prevParameters,
)
