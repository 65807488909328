import React, { memo, useCallback } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { FormButton, FormButtonType, PasswordField } from '@venatus/phoenix-components'

import { config } from '../../utils/config'
import useAuth from '../../hooks/useAuth'

const API = config('ACCOUNT_API')

const schema = yup.object().shape({
  password: yup.string().required('This field is required'),
  repeat: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password')], 'Passwords are not equal'),
})

const initialValues = {
  password: '',
  repeat: '',
}

export const PasswordSection = memo(({ user }) => {
  const { logout } = useAuth()

  const handleSubmit = useCallback(
    (data) => {
      fetch(`${API}organizations/${user.organization}/users/passwd`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          email: user.email,
          password: data.password,
        }),
      })
        .then(async (res) => {
          if (res && res.ok) {
            toast('Password changed, signing out ...', {
              type: 'success',
            })

            setTimeout(() => {
              logout();
            }, 2000);
          } else {
            toast('Failed to change password', {
              type: 'error',
            })
          }
        })
    },
    [user, logout],
  )

  return (
    <Card>
      <Card.Header>
        <h2 className="mb-0">Change password</h2>
      </Card.Header>
      <Card.Body>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange
        >
          <Form>
            <Row>
              <PasswordField
                name="password"
                label="New password"
                placeholder="Enter new password"
              />
              <PasswordField
                name="repeat"
                label="Repeat new password"
                placeholder="Repeat new password"
              />
            </Row>
            <Row>
              <Col xs={12} md={6} className="d-flex flex-row gap-2">
                <FormButton label="Change password" type={FormButtonType.SUBMIT} />
                <FormButton label="Reset" variant="warning" type={FormButtonType.RESET} />
              </Col>
            </Row>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  )
})

PasswordSection.displayName = 'ChangePasswordForm'
