import { useState } from 'react'
import { Alert } from 'react-bootstrap'

import style from './Beta.module.css'

const BetaAlert = () => {
  const [isDismissed, setIsDismissed] = useState(false)
  if (isDismissed) {
    return null
  }
  return (
    <Alert
      variant="info"
      className={style['beta-alert']}
      onClose={() => setIsDismissed(true)}
      dismissible
    >
      <span>
        <strong>Notice: </strong>This is a Beta application that may contain occasional bugs. Please
        report any issues encountered to the Venatus Publisher Operations team.
      </span>
    </Alert>
  )
}

export default BetaAlert
