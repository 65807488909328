export function compareNumbers(a, b) {
  const delta = a - b
  return delta > 0 ? 1 : delta < 0 ? -1 : 0
}

export function numberSort(rowA, rowB, key) {
  const a = rowA.original[key]
  const b = rowB.original[key]
  return compareNumbers(a, b)
}

export function datesSort(rowA, rowB, key) {
  const a = new Date(rowA.original[key])
  const b = new Date(rowB.original[key])
  return compareNumbers(a.getTime(), b.getTime())
}
