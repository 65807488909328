import { useMemo } from 'react'
import { Pagination } from 'react-bootstrap'

const PAGE_RANGE = 5

export default function usePagination({ pageIndex, pageOptions, gotoPage }) {
  const pageRowIndex = Math.floor(pageIndex / PAGE_RANGE)
  const pages = useMemo(() => {
    const startIndex = pageRowIndex * PAGE_RANGE
    const lastIndex = startIndex + PAGE_RANGE
    const currentPageOptions = pageOptions.slice(startIndex, lastIndex)
    const lastPageRowIndex = Math.ceil(pageOptions.length / PAGE_RANGE) - 1
    const pageItems = []

    if (pageRowIndex > 0) {
      pageItems.push(
        <Pagination.Item key={0} active={false} onClick={() => gotoPage(0)}>
          1
        </Pagination.Item>,
      )

      if (pageIndex > 1) {
        pageItems.push(
          <Pagination.Ellipsis
            key="prev"
            onClick={() => gotoPage(Math.max(0, pageIndex - PAGE_RANGE))}
          />,
        )
      }
    }

    currentPageOptions.forEach((option, index) => {
      pageItems.push(
        <Pagination.Item
          key={startIndex + index}
          active={option === pageIndex}
          onClick={() => gotoPage(option)}
        >
          {option + 1}
        </Pagination.Item>,
      )
    })

    if (pageRowIndex < lastPageRowIndex) {
      if (lastIndex + 1 < pageOptions.length - 1) {
        pageItems.push(
          <Pagination.Ellipsis
            key="next"
            onClick={() => gotoPage(Math.min(pageOptions.length - 1, pageIndex + PAGE_RANGE))}
          />,
        )
      }

      pageItems.push(
        <Pagination.Item
          key={pageOptions.length - 1}
          active={false}
          onClick={() => gotoPage(pageOptions.length - 1)}
        >
          {pageOptions.length}
        </Pagination.Item>,
      )
    }

    return pageItems
  }, [gotoPage, pageIndex, pageRowIndex, pageOptions])

  return { pages }
}
