import { useCallback, useState } from 'react'

import { useUserContext } from '../contexts/userContext'
import { login as loginRequest, logout as logoutRequest } from '../requests/auth'

const useAuth = () => {
  const {
    user,
    activeAccount,
    activeOrg,
    isAdmin,
    isLoading,
    setUser,
    updateUserData,
    selectAccount,
    selectOrganization,
  } = useUserContext()
  const isAuthenticated = !!user
  const logout = useCallback(async () => {
    if (!isAuthenticated) {
      return
    }
    return logoutRequest().then(() => {
      setUser(null)
    })
  }, [isAuthenticated, setUser])

  const [isTfaVisible, setIsTfaVisible] = useState(false)

  const handleAuthSuccess = useCallback(
    (user) => {
      setUser(user)
      selectAccount()
      selectOrganization()
      return user
    },
    [selectAccount, selectOrganization, setUser],
  )

  const handleAuthentication = useCallback(
    async (payload) => {
      try {
        const response = await loginRequest(payload)

        if (!response.ok) {
          if (response.status === 400) {
            setIsTfaVisible(true)
            return
          } else {
            throw new Error('Invalid email or password')
          }
        }

        setIsTfaVisible(false)
        const data = await response.json()
        handleAuthSuccess(data.user)
        return data
      } catch (error) {
        setUser(null)
        throw error
      }
    },
    [handleAuthSuccess, setUser],
  )

  const handleTfaCancel = useCallback(() => {
    setIsTfaVisible(null)
  }, [setIsTfaVisible])

  return {
    isAuthenticated,
    isTfaVisible,
    handleAuthentication,
    handleTfaCancel,
    isAdmin,
    isLoading,
    logout,
    updateUserData,
    user,
    activeAccount,
    activeOrg: isAdmin ? activeOrg : user?.organization,
    selectAccount,
    selectOrganization,
  }
}

export default useAuth
