import { getInvoices } from './getInvoices'

export const getInvoice = async (organizationId, accountId, id) => {
  try {
    const json = await getInvoices(organizationId, accountId)
    const result = json.find((invoice) => invoice.id === id)
    return result
  } catch (error) {
    return error
  }
}
