import React, { memo, useCallback, useMemo } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { FormButton, FormButtonType, TextField } from '@venatus/phoenix-components'

import useAuth from '../../hooks/useAuth'
import { config } from '../../utils/config'

const API = config('ACCOUNT_API')

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup.string().required('This field is required'),
})

export const UserInfoSection = memo(() => {
  const { user, updateUserData } = useAuth()

  const initialValues = useMemo(
    () => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    }),
    [user],
  )

  const handleSubmit = useCallback(
    (data) => {
      return fetch(`${API}organizations/${user.organization}/users`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          ...data,
          create: false,
        }),
      })
        .then(() => {
          toast(
            'Your changes have been saved but may not be visible on this page until your next login',
            {
              type: 'success',
              autoClose: 10_000,
            },
          )
          return updateUserData()
        })
        .catch((e) => {
          console.error(e)
          toast('Failed to submit form', {
            type: 'error',
          })
        })
    },
    [user.organization, updateUserData],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      validationSchema={schema}
    >
      <Card>
        <Card.Header>
          <h2 className="mb-0">Edit user data</h2>
        </Card.Header>
        <Card.Body className="p-5">
          <Form>
            <Row>
              <TextField label="Email" name="email" disabled />
              <TextField label="First name" name="firstName" />
              <TextField label="Last name" name="lastName" />
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormButton label="Update user" type={FormButtonType.SUBMIT} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Formik>
  )
})

UserInfoSection.displayName = 'ChangeUserData'
