import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ThemeProvider } from '@material-ui/core'

import { ErrorBoundary } from '@venatus/phoenix-components'

import AccountDetails from './components/AccountDetails'
import Accounts from './components/Accounts'
import BetaAlert from './components/Beta/Beta'
import Dashboard from './components/Dashboard'
import Invoice from './components/Invoice'
import Invoices from './components/Invoices'
import Layout from './components/Layout'
import LoginPage from './components/LoginPage'
import { LogoutPage } from './components/LogoutPage/LogoutPage'
import MyUser from './components/MyUser'
import { Organizations } from './components/Organizations'
import PaymentsDetails from './components/PaymentsDetails'
import PaymentsHistory from './components/PaymentsHistory'
import Reporting from './components/Reporting'
import { SwitchToAdmin } from './components/SwitchToAdmin/SwitchToAdmin'
import Preloader from './components/general/Preloader'
import { UserContextProvider } from './contexts/userContext'
import useAuth from './hooks/useAuth'
import useUsersnap from './hooks/useUsersnap'
import theme from './theme'
import './theme/index.scss'
import unauthorizedInterceptor from './utils/setup-unauthorized-check'

const onlyAdmin = (Component) => (props) => {
  const { isAdmin } = useAuth()

  return (
    <Route
      {...props}
      render={(props) => {
        if (!isAdmin) {
          return <Redirect to="/dashboard" />
        }

        return <Component {...props} />
      }}
    />
  )
}

const withOrganization = (Component) => (props) => {
  const { activeOrg } = useAuth()

  return (
    <Route
      {...props}
      render={(props) => {
        if (!activeOrg) {
          return <Redirect to="/organizations" />
        }

        return <Component {...props} />
      }}
    />
  )
}

const withLayout = (Component) => () =>
  (
    <Layout>
      <BetaAlert />
      {<Component />}
    </Layout>
  )

const Pages = () => {
  const { isAuthenticated, isLoading } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      unauthorizedInterceptor.apply()
    }
  }, [isAuthenticated])

  useUsersnap()

  if (isLoading) {
    return <Preloader />
  }

  if (!isAuthenticated) {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="*" component={() => <Redirect to="/login" />} />
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <Switch>
        <Route path="/reporting" component={withLayout(withOrganization(Reporting))} />
        <Route path="/dashboard" component={withLayout(withOrganization(Dashboard))} />
        <Route path="/accounts" component={withLayout(Accounts)} />
        <Route path="/organizations" component={withLayout(onlyAdmin(Organizations))} />
        <Route path="/account-details" component={withLayout(withOrganization(AccountDetails))} />
        <Route path="/user" component={withLayout(MyUser)} />
        <Route path="/invoices" component={withLayout(withOrganization(Invoices))} />
        <Route
          path="/invoice/:orgId/:accId/:id"
          component={withLayout(withOrganization(Invoice))}
        />
        <Route path="/paymentsdetails" component={withLayout(withOrganization(PaymentsDetails))} />
        <Route path="/paymentshistory" component={withLayout(withOrganization(PaymentsHistory))} />
        <Route path="/logout" component={withLayout(LogoutPage)} />
        <Route path="/switch-to-admin" component={withLayout(SwitchToAdmin)} />
        <Route path="/" component={() => <Redirect to="/dashboard" />} />
      </Switch>
    </Router>
  )
}

const queryClient = new QueryClient()

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
)

const App = () => {
  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <ErrorBoundary
      title="Sorry, we’re having trouble completing this action."
      subTitle="Please try again later or contact Venatus Publisher Operations for assistance."
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <ToastContainer />
            <Pages />
          </UserContextProvider>
          <ReactQueryDevtools />
          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
export default App
