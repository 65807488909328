import { config } from '../utils/config'

const API = config('ACCOUNT_API')

export function login({ email, password, tfaCode = '' }) {
  return fetch(`${API}auth/login`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, tfaCode }),
  })
}

export function logout() {
  return fetch(`${API}auth/logout`, {
    credentials: 'include',
    mode: 'cors',
  })
}
