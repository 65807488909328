import { useEffect, useState } from 'react'

import useAuth from '../hooks/useAuth'
import { getInvoices } from '../requests/getInvoices'

const useInvoices = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user, activeAccount, activeOrg } = useAuth()

  useEffect(() => {
    if (!activeOrg || !activeAccount) {
      return
    }

    getInvoices(activeOrg, activeAccount)
      .then((response) => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [activeOrg, activeAccount, user])

  return {
    data,
    isLoading,
  }
}

export default useInvoices
