import React, { memo, useCallback, useEffect } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { subDays } from 'date-fns'

import useReporting from '../../hooks/useReporting'
import Groupings from './Groupings'
import Range from './Range'
import ReportingContent from './ReportingContent'

const dateNow = new Date()

const createReportGroup = (name, label) => ({ name, label })

const reportsGroups = [
  createReportGroup('country', 'Country'),
  createReportGroup('device', 'Device'),
  createReportGroup('win_type', 'Win Type'),
  createReportGroup('site_name', 'Site'),
  createReportGroup('placement_name', 'Placement'),
  // createReportGroup("bidder", "Bidder"), ENG-4206
  createReportGroup('size', 'Size'),
]

const Reporting = () => {
  const {
    data,
    error,
    handleChange,
    handleSubmit,
    isInvalid,
    isLoading,
    meta,
    parameters,
    loaded,
  } = useReporting({
    granularity: 'DAY',
    from: subDays(dateNow, 1),
    to: dateNow,
    groupBy: ['site_name'],
  })

  useEffect(() => {
    if (!error) return
    toast.error(error.message)
  }, [error])

  const handlerSubmit = useCallback(
    (e) => {
      e.preventDefault()
      handleSubmit()
    },
    [handleSubmit],
  )

  const handlerDataChange = ({ target }) => {
    handleChange(target)
  }

  return (
    <div className="main-content mt-4">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">Admin</h6>
                <h1 className="header-title text-truncate">Reporting</h1>
              </Col>
            </Row>
          </div>
        </div>

        <Form noValidate onSubmit={handlerSubmit} className="mb-5">
          <Row>
            <Col xs={12}>
              <Range parameters={parameters} onChange={handlerDataChange} />
              <Groupings
                options={reportsGroups}
                value={parameters.groupBy}
                onChange={handleChange}
              />

              <Container fluid>
                <Button disabled={isLoading || isInvalid} type="submit">
                  Run Report
                </Button>
              </Container>
            </Col>
          </Row>
        </Form>

        <ReportingContent {...{ data, meta, isLoading, loaded, parameters }} />
      </Container>
    </div>
  )
}

export default memo(Reporting)
