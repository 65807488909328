import { useCallback, useMemo, useRef } from 'react'
import { Col, Form } from 'react-bootstrap'

import { useField } from 'formik'

import { PhoenixSelect } from '@venatus/phoenix-components'

import { countriesSelectOptions } from '../../requests/countries'

export const SelectField = ({ label, name, options, isMulti, xs = 12, md = 6, ...rest }) => {
  const [field, meta, helpers] = useField(name)
  const getValue = useCallback((val) => val.value, [])
  const selectRef = useRef(null)

  const value = useMemo(() => {
    if (isMulti) {
      return options.filter((el) => field.value.includes(el.value))
    }
    return options.find((el) => field.value.includes(el.value))
  }, [field.value, rest])

  const handleChange = useCallback(
    (value) => {
      const newValue = isMulti ? value.map(getValue) : getValue(value)

      helpers.setTouched(true)
      helpers.setValue(newValue)
    },
    [rest.isMulti, helpers, getValue],
  )

  return (
    <Col xs={xs} md={md}>
      <Form.Group className="form-group">
        <Form.Label>{label}</Form.Label>
        <PhoenixSelect
          placeholder="Select"
          {...rest}
          value={value}
          ref={selectRef}
          options={options}
          getOptionValue={getValue}
          getValue={getValue}
          onChange={handleChange}
        />
        {/* Use hidden Form.Control for correct work of Form.Control.Feedback */}
        <Form.Control className="d-none" isInvalid={!!(meta.error && meta.touched)} {...rest} />
        {meta.error && meta.touched && (
          <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  )
}

export const CountrySelectField = ({ ...props }) => {
  return <SelectField {...props} isMulti={false} options={countriesSelectOptions} />
}
