import React, { useMemo } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

import format from 'date-fns/format'

import { formatLocaleString } from '@venatus/phoenix-theme/helpers'

import useAuth from '../../hooks/useAuth'
import useInvoices from '../../hooks/useInvoices'
import { compareNumbers } from '../../utils/sorting'
import EmptyComponent from '../general/EmptyComponent'
import PagedTable from '../general/PagedTable/PagedTable'
import Preloader from '../general/Preloader'

function datesSort(rowA, rowB) {
  const a = new Date(rowA.original.year, rowA.original.month)
  const b = new Date(rowB.original.year, rowB.original.month)
  return compareNumbers(a.getTime(), b.getTime())
}

const Invoices = () => {
  const { data, isLoading } = useInvoices()

  const columns = useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'month',
        canSort: true,
        Cell: (props) => {
          const { month, year, status } = props?.row?.original
          const isAccuring = status === 'ACCRUING'
          const value = format(new Date(year, month - 1), 'MMMM yyyy')
          return isAccuring ? <div className="text-muted">{value}</div> : value
        },
        sortType: datesSort,
        className: 'col-xl-4',
      },
      {
        Header: 'Revenue',
        accessor: 'revenue',
        Cell: (props) => {
          const value = formatLocaleString(Math.abs(props.value), {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })
          return <div>{value}</div>
        },

        className: 'col-xl-6',
      },
      {
        accessor: 'Button',
        Cell: (props) => {
          const { activeAccount, activeOrg } = useAuth()
          const { id, status } = props?.row?.original
          const isDisabled = status === 'ACCRUING'
          return (
            <Button
              variant="primary"
              onClick={() => {
                window.open(`/invoice/${activeOrg}/${activeAccount}/${id}`, '_blank', 'noreferrer')
              }}
              disabled={isDisabled}
            >
              DOWNLOAD INVOICE
            </Button>
          )
        },
        className: 'col-xl-2',
        disableSortBy: true,
      },
    ],
    [],
  )
  const defaultSortBy = useMemo(
    () => [
      {
        id: 'month',
        desc: true,
      },
    ],
    [],
  )

  if (isLoading) {
    return <Preloader />
  }

  if (!data?.length) {
    return <EmptyComponent />
  }

  return (
    <div className="main-content">
      <Container fluid>
        <div className="mt-md-5">
          <div className="header-body border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">Payments</h6>
                <h1 className="header-title text-truncate">Invoices</h1>
              </Col>
            </Row>
          </div>
        </div>

        <Row className="justify-content-center">
          <Col xs={12}>
            <PagedTable data={data} columns={columns} defaultSortBy={defaultSortBy} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Invoices
